/**
 * Gatsby's Browser APIs.
 */
import * as React from "react"
import type { GatsbySSR, GatsbyBrowser } from "gatsby"
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { QueryParamProvider } from "use-query-params";
import { ReachAdapter } from "use-query-params/adapters/reach";
import { CookiesProvider, useCookies } from 'react-cookie';

import { getNewUUID, isValidUuid } from './src/utils/utils';
import useRunOnce from './src/hooks/useRunOnce'


const queryClient = new QueryClient()

type VisitorCookieProviderProps = {
	children: React.ReactNode
}
const VisitorCookieProvider = ({ children }:VisitorCookieProviderProps):JSX.Element => {
	const [cookies, setCookie] = useCookies(['visitorUUID']);
	const [visitorUuid, setVisitorUuid] = React.useState<string>('');

	useRunOnce({
		fn: () => {
			let localVisitorUuid = cookies?.visitorUUID;
			if (!isValidUuid(localVisitorUuid)) {
				if ((typeof window !== 'undefined') && (window) && (window?.location)) {
					localVisitorUuid = getNewUUID();
					console.debug(`VisitorProvider: initial visitorUuid ${cookies?.visitorUUID} is not a valid UUID so set to ${localVisitorUuid}`);
				} else {
					localVisitorUuid = '00000000-0000-4000-8000-000000000001';
					console.warn(`VisitorProvider: This is a Gatsby build so set UUID to ${localVisitorUuid}`);
				}
				setCookie('visitorUUID', localVisitorUuid, { path: '/', maxAge: 5 * 365 * 24 * 3600, sameSite: 'strict', secure: true });
			}
			setVisitorUuid(localVisitorUuid)
		},
		// Session storage key ensures that the callback only runs once per session.
		// sessionKey: "changeMeAndFnWillRerun"
	});

	return isValidUuid(visitorUuid) ? (<>{children}</>) : (<></>)
};

export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({ element }) => {
	return (
		<CookiesProvider>
			<VisitorCookieProvider>
				<QueryClientProvider client={queryClient}>
					<QueryParamProvider adapter={ReachAdapter}>
						{element}
						<ReactQueryDevtools initialIsOpen={false} />
					</QueryParamProvider>
				</QueryClientProvider>
			</VisitorCookieProvider>
		</CookiesProvider>
	);
}



// const headComponents = [
//   <script src="/nr-setup.js" />,
//   <script src="/build/nr-loader-spa.min.js" />,
// ]
// export const onRenderBody = ({ setHeadComponents }:any) => {
//   setHeadComponents(headComponents)
// }


// import { ErrorHero } from "./src/components/ErrorHero";
// const FallbackComponent = (error:any) => (
// 	<ErrorHero errorMessage={`Uh oh! There was an error : ${JSON.stringify(error)}`} />
// );
//export function wrapPageElement({ element }) {
//	return (
//		<Sentry.ErrorBoundary fallback={(error) => <FallbackComponent error={error} />}>
//			{element}
//		</Sentry.ErrorBoundary>
//	);
//}
