exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-500-tsx": () => import("./../../../src/pages/500.tsx" /* webpackChunkName: "component---src-pages-500-tsx" */),
  "component---src-pages-activate-tsx": () => import("./../../../src/pages/activate.tsx" /* webpackChunkName: "component---src-pages-activate-tsx" */),
  "component---src-pages-affiliate-enquiries-tsx": () => import("./../../../src/pages/affiliate-enquiries.tsx" /* webpackChunkName: "component---src-pages-affiliate-enquiries-tsx" */),
  "component---src-pages-checkout-tsx": () => import("./../../../src/pages/checkout.tsx" /* webpackChunkName: "component---src-pages-checkout-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-friend-tsx": () => import("./../../../src/pages/friend.tsx" /* webpackChunkName: "component---src-pages-friend-tsx" */),
  "component---src-pages-friends-tsx": () => import("./../../../src/pages/friends.tsx" /* webpackChunkName: "component---src-pages-friends-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-meet-the-dogs-tsx": () => import("./../../../src/pages/meet-the-dogs.tsx" /* webpackChunkName: "component---src-pages-meet-the-dogs-tsx" */),
  "component---src-pages-mission-tsx": () => import("./../../../src/pages/mission.tsx" /* webpackChunkName: "component---src-pages-mission-tsx" */),
  "component---src-pages-news-tsx": () => import("./../../../src/pages/news.tsx" /* webpackChunkName: "component---src-pages-news-tsx" */),
  "component---src-pages-order-status-tsx": () => import("./../../../src/pages/order-status.tsx" /* webpackChunkName: "component---src-pages-order-status-tsx" */),
  "component---src-pages-order-tsx": () => import("./../../../src/pages/order.tsx" /* webpackChunkName: "component---src-pages-order-tsx" */),
  "component---src-pages-partner-tsx": () => import("./../../../src/pages/partner.tsx" /* webpackChunkName: "component---src-pages-partner-tsx" */),
  "component---src-pages-press-enquiries-tsx": () => import("./../../../src/pages/press-enquiries.tsx" /* webpackChunkName: "component---src-pages-press-enquiries-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-science-tsx": () => import("./../../../src/pages/science.tsx" /* webpackChunkName: "component---src-pages-science-tsx" */),
  "component---src-pages-social-wall-2-tsx": () => import("./../../../src/pages/social-wall2.tsx" /* webpackChunkName: "component---src-pages-social-wall-2-tsx" */),
  "component---src-pages-social-wall-tsx": () => import("./../../../src/pages/social-wall.tsx" /* webpackChunkName: "component---src-pages-social-wall-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../../../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */),
  "component---src-templates-friend-tsx": () => import("./../../../src/templates/friend.tsx" /* webpackChunkName: "component---src-templates-friend-tsx" */),
  "component---src-templates-news-article-tsx": () => import("./../../../src/templates/news-article.tsx" /* webpackChunkName: "component---src-templates-news-article-tsx" */),
  "slice---src-slices-announcement-tsx": () => import("./../../../src/slices/announcement.tsx" /* webpackChunkName: "slice---src-slices-announcement-tsx" */),
  "slice---src-slices-black-friday-tsx": () => import("./../../../src/slices/black-friday.tsx" /* webpackChunkName: "slice---src-slices-black-friday-tsx" */),
  "slice---src-slices-hero-slice-tsx": () => import("./../../../src/slices/hero-slice.tsx" /* webpackChunkName: "slice---src-slices-hero-slice-tsx" */),
  "slice---src-slices-pack-instructions-tsx": () => import("./../../../src/slices/pack-instructions.tsx" /* webpackChunkName: "slice---src-slices-pack-instructions-tsx" */),
  "slice---src-slices-quote-tsx": () => import("./../../../src/slices/quote.tsx" /* webpackChunkName: "slice---src-slices-quote-tsx" */),
  "slice---src-slices-register-now-tsx": () => import("./../../../src/slices/register-now.tsx" /* webpackChunkName: "slice---src-slices-register-now-tsx" */)
}

