// import dayjs from 'dayjs'
// import utc from 'dayjs/plugin/utc'
// import timezone from 'dayjs/plugin/timezone'  // dependent on utc plugin
import { PriceTypeType, BasePriceType } from '../types/supabase';
import { v4 as uuidv4 } from 'uuid';

// dayjs.extend(utc)
// dayjs.extend(timezone)
// dayjs.tz.setDefault("Europe/London")

export const randomString = (length:number) => {
	let result = '';
	const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
	const charactersLength = characters.length;

	for (let i = 0; i < length; i++) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength));
	}
	return result;
};

export const getNewUUID = () => {
	// return crypto.randomUUID()
	return uuidv4();
}

export const isValidUuid = (uuid:string|undefined|null) => {
	/*
	UUID v1 : /^[0-9A-F]{8}-[0-9A-F]{4}-[1][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i
	UUID v2 : /^[0-9A-F]{8}-[0-9A-F]{4}-[2][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i
	UUID v3 : /^[0-9A-F]{8}-[0-9A-F]{4}-[3][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i
	UUID v4 : /^[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i
	UUID v5 : /^[0-9A-F]{8}-[0-9A-F]{4}-[5][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i
	*/
	if (uuid === undefined || uuid === null) return false;
	return uuid.toUpperCase().match(/^[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i) !== null;
}

export const isValidShortCode = (shortcode:string|undefined|null) => {
	if (shortcode === undefined || shortcode === null) return false;
	//BCDFGHJKLMNPQRSTVWXYZ
	return shortcode.toUpperCase().match(/^[BCDFGHJKLMNPQRSTVWXYZ]{3}-{0,1}[BCDFGHJKLMNPQRSTVWXYZ]{3}$/i) !== null;
}

export const getCurrentTimestamp = () => {
	return new Date().getTime();
};
export const getCurrentISOTimestamp = () => {
	return new Date().toISOString();
};
export const getCurrentISODate = () => {
	return new Date().toISOString().substring(0, 10);
};

export const getBase64 = (file:Blob) => {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();

		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result);
		reader.onerror = (error) => reject(error);
	});
};

export const getCookie = (name:string) => document?.cookie?.split('; ').filter(row => row.startsWith('cookie_name=')).map(c=>c.split('=')[1])[0]

export const formatPrice = (amount:number, currency:string = 'GBP') => {
	let numberFormat = new Intl.NumberFormat(['en-GB'], {
		style: 'currency',
		currency: currency,
		currencyDisplay: 'symbol',
		minimumFractionDigits: 2,
	});
	if (amount == null) {
		return '';
	}
	return (amount >= 0) ? numberFormat.format(amount / 100) : '-' + numberFormat.format(-amount / 100) + '';
};

// export const findProduct = (products, product_id) => {
// 	const result = products?.find(product => product._id === product_id);
// 	if (result) {
// 		return result;
// 	} else {
// 		for (let i = 0; i < products.length; i++) {
// 			const sub_products = products[i]?.sub_products ?? null;
// 			if (sub_products) {
// 				const result = findProduct(sub_products, product_id);
// 				if (result) {
// 					return result;
// 				}
// 			}
// 		}
// 		return null
// 	}
// }

export const dateRangeValid = (valid_start:string|undefined|null, valid_end:string|undefined|null) => {
	if (!valid_start || !valid_end) return false
	const start = new Date(valid_start)
	const end = new Date(valid_end)
	const now = new Date()
	if (isNaN(start.valueOf()) || isNaN(end.valueOf())) return false
	// const nowTime = dayjs().tz("Europe/London", true)
	// const dateValid:boolean = dayjs(valid_start).tz("Europe/London", true).isBefore(nowTime) && dayjs(valid_end).tz("Europe/London", true).isAfter(nowTime)
	const dateValid = (start < now) && (end > now)
	return dateValid
}
export const getPrimaryPrice = (product_name:string, basePrices:PriceTypeType['base_prices']|undefined):BasePriceType|undefined => {
	return basePrices?.price_by_full_name?.find(p => (p.product_name === product_name) && dateRangeValid(p.valid_start, p.valid_end))?.primary_price;
}

export function isValidHttpUrl(testurl: string) : boolean {
  let url;
  try {
    url = new URL(testurl);
  } catch (_) {
    return false;
  }
  return url.protocol === "http:" || url.protocol === "https:";
}
